import { defineComponent, onMounted, h } from '@vue/composition-api'
import { LIVE_ADMIN_URL } from '@/utils/api';
export default defineComponent({
  name: 'Admin',
  setup(){
    onMounted(()=> {
      window.location.replace(LIVE_ADMIN_URL);
    });

    return ()=> h('div');
  }
})
